import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatCalendar } from '@angular/material/datepicker';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-date-list-picker',
  templateUrl: './date-list-picker.component.html',
  styleUrls: ['./date-list-picker.component.scss'],
})
export class DateListPickerComponent {
  @Input() dateListFormControl: UntypedFormControl;
  @Input() label: string;

  constructor() {}

  get isoDatesList(): string[] {
    return this.dateListFormControl.value;
  }

  get isoDatesListText(): string {
    return this.isoDatesList
      .map((isoDate) => DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED))
      .join(', ');
  }

  selectCalendarDate(selectedDate: Date | null, calendar: MatCalendar<any>): void {
    if (selectedDate === null) {
      return;
    }

    const isoDate = this.dateToIsoString(selectedDate);
    const index = this.isoDatesList.findIndex((selectedDate) => selectedDate === isoDate);

    if (index < 0) {
      this.isoDatesList.push(isoDate);
    } else {
      this.isoDatesList.splice(index, 1);
    }

    this.isoDatesList.sort();

    calendar.updateTodaysDate();
  }

  dateToIsoString(date: Date): string {
    return date.toISOString().substring(0, 10);
  }

  dateSelectionClass = (date: Date): string => {
    return this.isoDatesList.find((selectedDate) => selectedDate === this.dateToIsoString(date))
      ? 'multi-selected'
      : '';
  };
}
