import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';

import { SegmentRequest, SegmentRequestOptions, SegmentRequestsService } from '../';
import { SessionRequestsService } from '../../session-requests';
import { BreadcrumbStateData } from '../../shared/breadcrumbs/breadcrumbs.service';
import { ListDataSource } from '../../shared/cdk';
import { ChildStatusCounts } from '../../shared/child-status-counts/child-status-counts.model';
import { SegmentRequestsDetailsComponent } from '../segment-requests-details/segment-requests-details.component';

@UntilDestroy()
@Component({
  selector: 'app-segment-requests-list',
  templateUrl: './segment-requests-list.component.html',
  styleUrls: ['./segment-requests-list.component.scss'],
})
export class SegmentRequestsListComponent implements OnInit, AfterViewInit {
  public pageTitle = 'Segment Requests';
  public pageSubtitle: string;

  public dataSource: ListDataSource<SegmentRequest, SegmentRequestsService>;
  public pageSizeOptions: number[] = [10, 25, 100];
  public initialPageSize = this.pageSizeOptions[0];
  public displayedColumns = [
    'googleMessageId',
    'status',
    'updatedAt',
    'segments',
    'bridgeIds',
    'bridgeIdsMatched',
    'actions',
  ];

  public statuses = [
    'ACCEPTED',
    'PROCESSING',
    'DECRYPTING',
    'DECRYPTED',
    'PROCESSED',
    'DONE',
    'ABORTED',
  ];
  public statusFilter = '';
  public searchFilter = '';
  public sessionRequestId: string;
  public childStatusCounts: ChildStatusCounts;

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private segmentRequestsService: SegmentRequestsService,
    private sessionRequestsService: SessionRequestsService
  ) {
    if (this.route.snapshot.queryParams?.['sessionRequestId']) {
      this.sessionRequestId = this.route.snapshot.queryParams?.['sessionRequestId'];
      this.loadSessionRequest();
    }
  }

  ngOnInit() {
    this.dataSource = new ListDataSource(this.segmentRequestsService);
    this.paginator.pageSize = this.initialPageSize;
    this.sort.active = 'updatedAt';
    this.sort.direction = 'desc';

    this.loadPage();
  }

  ngAfterViewInit() {
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.loadPage(true));
    this.paginator.page.pipe(untilDestroyed(this)).subscribe(() => this.loadPage());
  }

  loadSessionRequest() {
    this.sessionRequestsService
      .get(this.sessionRequestId)
      .pipe(first())
      .subscribe((sessionRequest) => {
        this.pageSubtitle = `For Session Request ${sessionRequest.googleSessionId}`;
        this.childStatusCounts = sessionRequest.childStatusCounts;
      });
  }

  statusFilterChanged(): void {
    this.loadPage(true);
  }

  applySearchFilter(searchFilter: string): void {
    this.searchFilter = searchFilter;
    this.loadPage(true);
  }

  loadPage(resetPaginator = false): void {
    if (resetPaginator) {
      this.paginator.pageIndex = 0;
    }

    const params: SegmentRequestOptions = {};

    if (this.searchFilter) {
      params.searchFilter = this.searchFilter;
    }

    if (this.statusFilter) {
      params.status = this.statusFilter;
    }

    if (this.sessionRequestId) {
      params.sessionRequestId = this.sessionRequestId;
    }

    this.dataSource.query(
      `${this.sort.active},${this.sort.direction}`,
      this.paginator.pageSize,
      this.paginator.pageIndex,
      params
    );
  }

  openDetailsModal(segmentRequest: SegmentRequest): void {
    this.dialog.open(SegmentRequestsDetailsComponent, {
      panelClass: ['comsig-dialog', 'details-dialog'],
      width: '700px',
      data: segmentRequest,
    });
  }

  get breadcrumbLabel(): string {
    return this.pageSubtitle ? `${this.pageTitle} ${this.pageSubtitle}` : this.pageTitle;
  }

  get breadcrumbStateData(): BreadcrumbStateData {
    return {
      breadcrumb: {
        key: 'segmentRequests',
        label: this.breadcrumbLabel,
        route: this.route.routeConfig?.path,
        queryParams: this.route.snapshot.queryParams,
      },
    };
  }
}
