import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';

import { DataVerificationDialogComponent } from './data-verification-dialog/data-verification-dialog.component';

@NgModule({
  imports: [LayoutModule, SharedModule],
  declarations: [DataVerificationDialogComponent],
})
export class TransactionVerificationsModule {}
