<app-dialog-header (closeDialog)="close()">Add Session Request Control</app-dialog-header>
<form [formGroup]="form" novalidate>
  <mat-dialog-content>
    <div class="flex flex-col">
      <mat-form-field>
        <mat-label>Select Bad Merchants</mat-label>
        <mat-select formControlName="badMerchants" multiple>
          @for (merchant of merchantOptions; track merchant) {
          <mat-option [value]="merchant">
            {{ merchant.merchantName }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <app-date-list-picker
        [dateListFormControl]="badDatesFormControl"
        label="Bad Dates"
      ></app-date-list-picker>

      <app-date-list-picker
        [dateListFormControl]="pauseDatesFormControl"
        label="Pause Dates"
      ></app-date-list-picker>

      <mat-form-field>
        <mat-label>Optional Comments</mat-label>
        <textarea matInput formControlName="comments"> </textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type="button" (click)="close()">Cancel</button>
    <button
      mat-raised-button
      type="button"
      color="primary"
      [disabled]="isPending"
      (click)="submit()"
    >
      Submit
    </button>
  </mat-dialog-actions>
</form>
