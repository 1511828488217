import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { first } from 'rxjs/operators';

import { VERSION } from '../../../environments/version';
import { StatusService } from '../../status';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentYear: string;
  public version: string;
  public hover: string;

  constructor(
    private dialog: MatDialog,
    private statusService: StatusService
  ) {}

  ngOnInit() {
    this.currentYear = DateTime.local().toFormat('yyyy');

    this.version = VERSION.version;
    this.hover = `UI: ${VERSION.version}-${VERSION.hash}`;

    this.getApiVersion();
  }

  getApiVersion(): void {
    this.statusService
      .getVersion()
      .pipe(first())
      .subscribe((apiVersion) => {
        this.hover += ` • API: ${apiVersion.build.version}-${apiVersion.git.commit.id}`;
      });
  }
}
