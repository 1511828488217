import { Injectable } from '@angular/core';
import { ApiEntity, ApiService } from '@comsig/core';
import { Observable } from 'rxjs';

import {
  ActiveWorkQueues,
  WorkQueue,
  WorkQueueHistory,
  WorkQueueHistoryOptions,
} from './work-queues.model';

@Injectable({
  providedIn: 'root',
})
export class WorkQueuesService extends ApiEntity<WorkQueue> {
  protected override endpoint = 'work-queues';

  constructor(protected override api: ApiService) {
    super(api);
  }

  active(): Observable<ActiveWorkQueues> {
    return this.api.get(this.endpoint);
  }

  history(options: WorkQueueHistoryOptions = {}): Observable<WorkQueueHistory> {
    return this.api.get(`${this.endpoint}/history`, options);
  }
}
