import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { SegmentRequest } from '../segment-requests.model';

@Component({
  selector: 'app-segment-requests-details',
  templateUrl: './segment-requests-details.component.html',
  styleUrls: ['./segment-requests-details.component.scss'],
})
export class SegmentRequestsDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<SegmentRequestsDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public segmentRequest: SegmentRequest
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
