import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pingAuthGuard } from '@comsig/core';

import { MerchantsListComponent } from './merchants-list/merchants-list.component';

const routes: Routes = [
  {
    path: 'merchants',
    component: MerchantsListComponent,
    canActivate: [pingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MerchantsRoutingModule {}
