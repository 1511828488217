import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@comsig/core';

import { BreadcrumbsService } from './shared/breadcrumbs/breadcrumbs.service';
import { PermissionsService } from './shared/permissions';
import { RoutingStateService } from './shared/routing-state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public userLoaded = false;

  constructor(
    private router: Router,
    private routingStateService: RoutingStateService,
    private permissionsService: PermissionsService,
    private authService: AuthService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.routingStateService.loadRouting();
  }

  ngOnInit() {
    this.breadcrumbsService.startListening();
    this.authService.onAuthUserSet = () => {
      this.permissionsService.setupPermissions();

      this.userLoaded = true;
    };
  }
}
