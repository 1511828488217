<nav class="bg-dark-blue">
  <div class="container container-wide flex justify-start items-center">
    <img [src]="logo" alt="Commerce Signals Logo" />

    <div class="title flex-1">Google Dashboard</div>

    <app-header-menu> </app-header-menu>
  </div>
</nav>

<app-header-nav></app-header-nav>
