import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ComsigCoreModule } from '@comsig/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';

import { BridgeMapsModule } from './bridge-maps/bridge-maps.module';
import { LayoutModule } from './layout/layout.module';
import { MerchantsModule } from './merchants/merchants.module';
import { SchedulesModule } from './schedules/schedules.module';
import { SegmentRequestsModule } from './segment-requests/segment-requests.module';
import { SessionRequestsModule } from './session-requests/session-requests.module';
import { SharedModule } from './shared/shared.module';
import { StatusModule } from './status/status.module';
import { TransactionVerificationsModule } from './transaction-verifications/transaction-verifications.module';
import { WorkQueuesModule } from './work-queues/work-queues.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ComsigCoreModule,
    SharedModule,
    LayoutModule,
    StatusModule,
    BridgeMapsModule,
    MerchantsModule,
    SchedulesModule,
    SegmentRequestsModule,
    SessionRequestsModule,
    WorkQueuesModule,
    TransactionVerificationsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
