import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { Observable } from 'rxjs';

import { Merchant, MerchantOptions } from './merchants.model';

@Injectable({
  providedIn: 'root',
})
export class MerchantsService extends ApiEntity<Merchant> {
  protected override endpoint = 'merchants';

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(params: MerchantOptions = {}): Observable<PaginatedList<Merchant>> {
    return super.getList(params);
  }
}
