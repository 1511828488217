<div>
  <div class="flex justify-center items-center">
    <div class="w-1/5">
      <app-content-placeholder [rows]="6" [paddingRight]="10"></app-content-placeholder>
    </div>
    <div class="w-1/5">
      <app-content-placeholder [rows]="6" [padding]="10"></app-content-placeholder>
    </div>
    <div class="w-1/5">
      <app-content-placeholder [rows]="6" [paddingLeft]="10"></app-content-placeholder>
    </div>
    <div class="w-1/5">
      <app-content-placeholder [rows]="6" [padding]="10"></app-content-placeholder>
    </div>
    <div class="w-1/5">
      <app-content-placeholder [rows]="6" [paddingRight]="10"></app-content-placeholder>
    </div>
  </div>

  <div class="flex justify-center items-center">
    <div class="w-3/5 no-data-message">
      <h1 class="mat-display-1"><ng-content></ng-content></h1>
      <br />
    </div>
  </div>
</div>
