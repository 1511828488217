import { Component, OnInit } from '@angular/core';

import { NavItem, navItems } from './header-nav.items';

@Component({
  selector: 'app-header-nav',
  templateUrl: './header-nav.component.html',
  styleUrls: ['./header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit {
  public navItems: (NavItem | undefined)[] = [];

  constructor() {}

  ngOnInit() {
    this.navItems = [
      navItems.get('schedules'),
      navItems.get('sessionRequests'),
      navItems.get('segmentRequests'),
      navItems.get('bridgeMaps'),
      navItems.get('merchants'),
      navItems.get('workQueues'),
    ];
  }
}
