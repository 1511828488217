import { Component, OnInit } from '@angular/core';
import { finalize, first } from 'rxjs/operators';

import { ActiveWorkQueues } from '../work-queues.model';
import { WorkQueuesService } from '../work-queues.service';

@Component({
  selector: 'app-work-queues-active-list',
  templateUrl: './work-queues-active-list.component.html',
  styleUrls: ['./work-queues-active-list.component.scss'],
})
export class WorkQueuesActiveListComponent implements OnInit {
  public displayedColumns: string[] = ['queueName', 'queueSize', 'podCount'];

  public activeWorkQueues: ActiveWorkQueues;
  public isLoading = false;

  constructor(private workQueuesService: WorkQueuesService) {}

  ngOnInit(): void {
    this.retrieveActiveQueues();
  }

  retrieveActiveQueues(): void {
    this.isLoading = true;

    this.workQueuesService
      .active()
      .pipe(
        first(),
        finalize(() => (this.isLoading = false))
      )
      .subscribe((activeWorkQueues) => {
        this.activeWorkQueues = activeWorkQueues;
      });
  }

  get dataSource() {
    return this.activeWorkQueues?.workQueues;
  }

  get hasDatasource() {
    return this.dataSource?.length > 0;
  }
}
