import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { Observable } from 'rxjs';

import { Schedule, ScheduleOptions } from './schedules.model';

@Injectable({
  providedIn: 'root',
})
export class SchedulesService extends ApiEntity<Schedule> {
  protected override endpoint = 'schedules';

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(params: ScheduleOptions): Observable<PaginatedList<Schedule>> {
    return super.getList(params);
  }
}
