import { Component, Input, OnChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DateTime, DateTimeFormatOptions } from 'luxon';

import { WorkQueue, WorkQueueHistory } from '../../';

@Component({
  selector: 'app-work-queues-history-chart',
  templateUrl: './work-queues-history-chart.component.html',
  styleUrls: ['./work-queues-history-chart.component.scss'],
})
export class WorkQueuesHistoryChartComponent implements OnChanges {
  Highcharts = Highcharts;

  @Input() workQueueHistory: WorkQueueHistory;
  @Input() timestampFormat: DateTimeFormatOptions;

  public chartOptions: Highcharts.Options;

  constructor() {}

  ngOnChanges(): void {
    this.configChart();
  }

  configChart(): void {
    const colors = [
      '#7cb5ec',
      '#EAC645',
      '#A3C454',
      '#5A5DA0',
      '#C7B4FF',
      '#EF8A89',
      '#2CC6AA',
      '#F2AA3C',
      '#BE469C',
      '#0760E5',
    ];

    const series: any = [];

    this.workQueueHistory.queueNames.forEach((queueName, index) => {
      const color = index < colors.length ? colors[index] : null;

      const dataFor = (field: string) =>
        this.workQueueHistory.timestamps.map((workQueueTimestamp) => {
          const workQueue = workQueueTimestamp.workQueues.find(
            (workQueue) => workQueue.queueName === queueName
          );

          return workQueue ? workQueue[field as keyof WorkQueue] : 0;
        });

      series.push({
        name: `${queueName} Queue Size`,
        data: dataFor('queueSize'),
        color,
        yAxis: 0,
      });

      series.push({
        name: `${queueName} Pod Count`,
        data: dataFor('podCount'),
        color,
        dashStyle: 'ShortDot',
        yAxis: 1,
      });
    });

    this.chartOptions = {
      chart: {
        animation: false,
        zooming: {
          type: 'x',
        },
      },
      title: {
        text: undefined,
      },
      credits: {
        enabled: false,
      },
      yAxis: [
        {
          title: {
            text: 'Queue Size Max',
          },
          minTickInterval: 1,
        },
        {
          title: {
            text: 'Pod Count Max',
          },
          minTickInterval: 1,
          opposite: true,
        },
      ],
      xAxis: {
        categories: this.workQueueHistory.timestamps.map((workQueueTimestamp) =>
          this.timestampFormat === DateTime.DATE_SHORT
            ? DateTime.fromISO(workQueueTimestamp.timestamp)
                .setZone('UTC')
                .toLocaleString(this.timestampFormat)
            : DateTime.fromISO(workQueueTimestamp.timestamp).toLocaleString(this.timestampFormat)
        ),
      },
      legend: {
        itemMarginTop: 10,
      },
      plotOptions: {
        series: {
          lineWidth: 3,
          animation: false,
          marker: {
            enabled: false,
          },
        },
      },
      series,
    };
  }
}
