<app-dialog-header (closeDialog)="close()"> Schedule Details </app-dialog-header>

<mat-dialog-content>
  <div class="flex flex-col">
    <div class="row">
      <div>Statuses:</div>
      <div>
        <app-status-list [statuses]="schedule.statuses"></app-status-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
