import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { WorkQueuesActiveListComponent } from './work-queues-active-list/work-queues-active-list.component';
import { WorkQueuesHistoryChartComponent } from './work-queues-history/work-queues-history-chart/work-queues-history-chart.component';
import { WorkQueuesHistoryComponent } from './work-queues-history/work-queues-history.component';

import { WorkQueuesIndexComponent } from './work-queues-index/work-queues-index.component';

import { WorkQueuesRoutingModule } from './work-queues-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, WorkQueuesRoutingModule],
  declarations: [
    WorkQueuesIndexComponent,
    WorkQueuesActiveListComponent,
    WorkQueuesHistoryComponent,
    WorkQueuesHistoryChartComponent,
  ],
})
export class WorkQueuesModule {}
