@if (hasBreadcrumbs) {
<ul class="breadcrumbs">
  @for (breadcrumb of breadcrumbs; track breadcrumb) {
  <li>
    <a
      [routerLink]="['/' + breadcrumb.route]"
      [queryParams]="breadcrumb.queryParams"
      [state]="{ data: { toBreadcrumb: breadcrumb.key } }"
    >
      {{ breadcrumb.label }}
    </a>
  </li>
  }
  <li>
    {{ currentLabel }}
  </li>
</ul>
}
