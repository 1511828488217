<div class="user-menu">
  <span class="menu-item" (click)="openTransactionVerificationsModal()">
    <span> Data Verification </span>
  </span>

  <span class="menu-item" routerLink="/session-requests">
    <span
      [matBadge]="($activeControls | async)?.length"
      [matBadgeHidden]="!($activeControls | async)?.length"
      matBadgeOverlap="false"
      matBadgeColor="warn"
      *ngxPermissionsOnly="'pauseSessionRequests'"
    >
      Manage Controls
    </span>
  </span>
  <span class="name">
    {{ name }}
  </span>
  <span>
    <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
      <button mat-menu-item (click)="logout()">Logout</button>
    </mat-menu>
    <button id="e2e-user-icon" mat-icon-button [matMenuTriggerFor]="userMenu">
      <mat-icon class="cs cs-light-user-circle" aria-hidden="true"> </mat-icon>
    </button>
  </span>
</div>
