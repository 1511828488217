import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppSettingsService, AuthService } from '@comsig/core';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

import { SessionRequestControl, SessionRequestControlsService } from '../../../session-requests';
import { DataVerificationDialogComponent } from '../../../transaction-verifications/data-verification-dialog/data-verification-dialog.component';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit {
  public name = '';

  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private sessionRequestControlsService: SessionRequestControlsService,
    private appSettingsService: AppSettingsService
  ) {}

  ngOnInit() {
    this.name = this.authService.authUser.username;

    this.sessionRequestControlsService
      .getList()
      .pipe(first())
      .subscribe((sessionRequestControls) => {});
  }

  public logout() {
    const logoutUri = this.appSettingsService.getSettings().ping?.logoutUri ?? '/';
    this.authService.signOut(logoutUri);
  }

  openTransactionVerificationsModal(): void {
    this.dialog.open(DataVerificationDialogComponent, {
      panelClass: ['comsig-dialog', 'details-dialog'],
      width: '700px',
    });
  }

  get $activeControls(): Observable<SessionRequestControl[]> {
    return this.sessionRequestControlsService.activeControls$;
  }
}
