<mat-form-field class="flex-1 transparent-form-field">
  <input
    matInput
    class="e2e-search-input"
    placeholder="Search"
    [value]="defaultValue"
    #searchInput
  />
  <mat-icon matSuffix class="cs cs-search search-icon" aria-hidden="true"> </mat-icon>
</mat-form-field>
