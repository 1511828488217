<app-dialog-header (closeDialog)="close()"> Session Request Details </app-dialog-header>

<mat-dialog-content>
  <div class="flex flex-col">
    <div class="row">
      <div>Started At:</div>
      <div>{{ sessionRequest.createdAt | date: 'MMM d, y h:mm a' }}</div>
    </div>
    <div class="row">
      <div>Ended At:</div>
      <div>{{ sessionRequest.updatedAt | date: 'MMM d, y h:mm a' }}</div>
    </div>
    <div class="row">
      <div>Date Ranges:</div>
      <div>
        @for (dateRange of sessionRequest.dateRanges; track dateRange) {
        <div>{{ dateRange.startOn | date }} - {{ dateRange.endOn | date }}</div>
        }
      </div>
    </div>
    <div class="row">
      <div>Distinct Bridge IDs:</div>
      <div>{{ sessionRequest.distinctBridgeIds | number }}</div>
    </div>
    <div class="row">
      <div>Total Transactions:</div>
      <div>{{ sessionRequest.totalTransactions | number }}</div>
    </div>
    <div class="row">
      <div>Requested Fields:</div>
      <div>{{ sessionRequest.requestedFields }}</div>
    </div>
    <div class="row">
      <div>Ad Type:</div>
      <div>{{ sessionRequest.adType }}</div>
    </div>
    <div class="row">
      <div>Match Type:</div>
      <div>{{ sessionRequest.bridgeMap?.version }}</div>
    </div>
    <div class="row">
      <div>Options:</div>
      <div>
        @for (option of sessionRequest.options; track option) {
        <div>{{ option.name }}: {{ option.value }}</div>
        }
      </div>
    </div>
    <div class="row">
      <div>Statuses:</div>
      <div>
        <app-status-list [statuses]="sessionRequest.statuses"></app-status-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
