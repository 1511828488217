import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettingsService } from '@comsig/core';
import { Observable } from 'rxjs';

import { ApiVersionInfo, Status } from './status.model';

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  constructor(
    private http: HttpClient,
    private appSettings: AppSettingsService
  ) {}

  public getStatus(): Observable<Status> {
    return this.http.get<Status>(`${this.getApiEndpoint()}/actuator/health`);
  }

  public getVersion(): Observable<ApiVersionInfo> {
    return this.http.get<ApiVersionInfo>(`${this.getApiEndpoint()}/actuator/info`);
  }

  private getApiEndpoint() {
    return this.appSettings.getSettings().api.endpoint;
  }
}
