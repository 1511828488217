import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
  SessionRequestControl,
  SessionRequestControlOptions,
} from './session-request-controls.model';

@Injectable({
  providedIn: 'root',
})
export class SessionRequestControlsService extends ApiEntity<SessionRequestControl> {
  protected override endpoint = 'session-request-controls';

  private activeControlsSubject = new BehaviorSubject<SessionRequestControl[]>([]);
  public activeControls$ = this.activeControlsSubject.asObservable();

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(): Observable<PaginatedList<SessionRequestControl>> {
    const params: SessionRequestControlOptions = {
      sort: 'createdAt,desc',
      isActive: true,
    };

    return super.getList(params).pipe(tap((list) => this.activeControlsSubject.next(list.content)));
  }

  setActive(sessionRequestControl: SessionRequestControl, isActive: boolean) {
    return this.api.patch(`${this.endpoint}/${sessionRequestControl.id}`, { isActive });
  }
}
