@if (activeWorkQueues) {
<h2>Active Global Pod Count: {{ activeWorkQueues.globalPodCount | number }}</h2>
} @if (isLoading) {
<mat-spinner class="spinner"> </mat-spinner>
} @if (hasDatasource) {
<table class="table-striped" mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="queueName">
    <th mat-header-cell *matHeaderCellDef>Queue Name</th>
    <td mat-cell *matCellDef="let workQueue">
      {{ workQueue.queueName }}
    </td>
  </ng-container>

  <ng-container matColumnDef="queueSize">
    <th mat-header-cell *matHeaderCellDef class="right-align">Queue Size</th>
    <td mat-cell *matCellDef="let workQueue" class="right-align">
      {{ workQueue.queueSize | number }}
    </td>
  </ng-container>

  <ng-container matColumnDef="podCount">
    <th mat-header-cell *matHeaderCellDef class="right-align">Pod Count</th>
    <td mat-cell *matCellDef="let workQueue" class="right-align">
      {{ workQueue.podCount | number }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
}
