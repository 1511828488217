import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatPseudoCheckboxModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import * as Highcharts from 'highcharts';

import { HighchartsChartModule } from 'highcharts-angular';
import { NgxPermissionsModule } from 'ngx-permissions';

import { AddButtonComponent } from './add-button/add-button.component';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ChildStatusCountsComponent } from './child-status-counts/child-status-counts.component';

import { ConfirmDialogModule } from './confirm-dialog/confirm-dialog.module';
import { ContentPlaceholderSectionComponent } from './content-placeholder-section/content-placeholder-section.component';
import { ContentPlaceholderComponent } from './content-placeholder/content-placeholder.component';
import { DateListPickerComponent } from './date-list-picker/date-list-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DialogHeaderComponent } from './dialog-header/dialog-header.component';

import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { SearchBoxComponent } from './search-box/search-box.component';
import { StatusListComponent } from './status-list/status-list.component';

Highcharts.setOptions({ lang: { thousandsSep: ',' } });

@NgModule({
  declarations: [
    AddButtonComponent,
    BasicPageComponent,
    BreadcrumbsComponent,
    ChildStatusCountsComponent,
    ContentPlaceholderComponent,
    ContentPlaceholderSectionComponent,
    DateListPickerComponent,
    DateRangePickerComponent,
    DialogHeaderComponent,
    SearchBoxComponent,
    StatusListComponent,
    SanitizeHtmlPipe,
  ],
  exports: [
    CommonModule,
    AddButtonComponent,
    BasicPageComponent,
    BreadcrumbsComponent,
    ChildStatusCountsComponent,
    ContentPlaceholderComponent,
    ContentPlaceholderSectionComponent,
    DateListPickerComponent,
    DateRangePickerComponent,
    DialogHeaderComponent,
    SearchBoxComponent,
    StatusListComponent,
    BrowserAnimationsModule,
    FormsModule,
    NgxPermissionsModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    HighchartsChartModule,
    NgxPermissionsModule,
    ConfirmDialogModule,
    SanitizeHtmlPipe,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTooltipModule,
    MatTreeModule,
    NgxPermissionsModule.forRoot(),
    ConfirmDialogModule,
  ],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    SanitizeHtmlPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class SharedModule {}
