<app-basic-page>
  <app-page-header pageTitle="Bridge Maps"> </app-page-header>

  <div class="flex flex-col">
    <div class="flex gap-x-4">
      <app-search-box class="flex w-full" (searching)="applySearchFilter($event)"> </app-search-box>
      <mat-form-field class="w-80 transparent-form-field">
        <mat-label>Status</mat-label>
        <mat-select [(ngModel)]="statusFilter" (selectionChange)="statusFilterChanged()">
          <mat-option [value]="">All Statuses</mat-option>
          @for (status of statuses; track status) {
          <mat-option [value]="status">
            {{ status }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    @if (dataSource.totalNumRows === 0) {
    <app-content-placeholder-section>
      There are no results available.
    </app-content-placeholder-section>
    }

    <div [hidden]="dataSource.totalNumRows === 0">
      @if (dataSource.loading$ | async) {
      <div class="mat-table-spinner-container">
        <mat-spinner> </mat-spinner>
      </div>
      }
      <table class="table-striped" mat-table [dataSource]="dataSource" matSort matSortDisableClear>
        <ng-container matColumnDef="provider">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
          <td mat-cell *matCellDef="let row">
            {{ row.provider }}
          </td>
        </ng-container>

        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Version</th>
          <td mat-cell *matCellDef="let row">
            {{ row.version }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let row">
            {{ row.status }}
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated At</th>
          <td mat-cell *matCellDef="let row">
            {{ row.updatedAt | date: 'MMM d, y h:mm a' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" class="right-align">
          <th mat-header-cell *matHeaderCellDef class="right-align">Actions</th>
          <td mat-cell *matCellDef="let row" class="right-align">
            <button mat-icon-button [matMenuTriggerFor]="actionMenu">
              <mat-icon class="cs cs-more-vert" aria-label="Actions"></mat-icon>
            </button>
            <mat-menu #actionMenu="matMenu" xPosition="before">
              <button mat-menu-item (click)="openDetailsModal(row)">Details</button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator
        [length]="dataSource.totalNumRows"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="initialPageSize"
      >
      </mat-paginator>
    </div>
  </div>
</app-basic-page>
