import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { Observable } from 'rxjs';

import { SessionRequest, SessionRequestOptions } from './session-requests.model';

@Injectable({
  providedIn: 'root',
})
export class SessionRequestsService extends ApiEntity<SessionRequest> {
  protected override endpoint = 'session-requests';

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(
    params: SessionRequestOptions
  ): Observable<PaginatedList<SessionRequest>> {
    return super.getList(params);
  }
}
