import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { SegmentRequestsDetailsComponent } from './segment-requests-details/segment-requests-details.component';

import { SegmentRequestsListComponent } from './segment-requests-list/segment-requests-list.component';

import { SegmentRequestsRoutingModule } from './segment-requests-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, SegmentRequestsRoutingModule],
  declarations: [SegmentRequestsListComponent, SegmentRequestsDetailsComponent],
})
export class SegmentRequestsModule {}
