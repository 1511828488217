<div class="flex" [matMenuTriggerFor]="calendarMenu">
  <mat-form-field class="flex-1">
    <mat-label>{{ label }}</mat-label>
    <input matInput [value]="isoDatesListText" readonly />
  </mat-form-field>
  <button mat-icon-button>
    <mat-icon class="cs cs-calendar"></mat-icon>
  </button>
</div>
<mat-menu #calendarMenu="matMenu" class="drop-calendar">
  <div (click)="$event.stopPropagation()">
    <mat-calendar
      #calendar
      (selectedChange)="selectCalendarDate($event, calendar)"
      [dateClass]="dateSelectionClass"
    >
    </mat-calendar>
  </div>
</mat-menu>
