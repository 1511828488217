import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { Schedule } from '../schedules.model';

@Component({
  selector: 'app-schedules-details',
  templateUrl: './schedules-details.component.html',
  styleUrls: ['./schedules-details.component.scss'],
})
export class SchedulesDetailsComponent {
  constructor(
    public dialogRef: MatDialogRef<SchedulesDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public schedule: Schedule
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
