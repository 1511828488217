<div class="flex justify-start items-center">
  <h2 class="flex-1">Historical Counts</h2>
  <mat-form-field class="transparent-form-field">
    <mat-label>Timeframe</mat-label>
    <mat-select [(ngModel)]="timeframe" (selectionChange)="retrieveHistory()">
      @for (timeframe of timeframeOptions; track timeframe) {
      <mat-option [value]="timeframe">
        {{ timeframe.label }}
      </mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>

@if (!isLoading) { @if (workQueueHistory) {
<app-work-queues-history-chart
  [workQueueHistory]="workQueueHistory"
  [timestampFormat]="timeframe.timestampFormat"
></app-work-queues-history-chart>
} } @else {

<mat-spinner class="spinner"> </mat-spinner>

}
