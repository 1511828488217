import { Injectable } from '@angular/core';
import { NavigationStart, Router, RoutesRecognized } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface BreadcrumbStateData {
  breadcrumb: Breadcrumb;
}

export interface Breadcrumb {
  key: string;
  label: string;
  route: string | undefined;
  queryParams?: any;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  public breadcrumbs$ = this.breadcrumbsSubject.asObservable();

  private navigationTrigger?: string;

  constructor(private router: Router) {}

  startListening(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.navigationTrigger = event.navigationTrigger;
        return;
      }

      if (!(event instanceof RoutesRecognized)) {
        return;
      }

      if (this.navigationTrigger === 'popstate') {
        this.pop();
      }

      if (!this.router.getCurrentNavigation()?.extras.state) {
        this.clear();
        return;
      }

      if (this.router.getCurrentNavigation()?.extras.state?.['data'].toBreadcrumb) {
        this.to(this.router.getCurrentNavigation()?.extras.state?.['data'].toBreadcrumb);
        return;
      }

      if (
        this.router.getCurrentNavigation()?.extras.state?.['data'].breadcrumb &&
        this.navigationTrigger !== 'popstate'
      ) {
        this.add(this.router.getCurrentNavigation()?.extras.state?.['data'].breadcrumb);
      }
    });
  }

  clear(): void {
    this.breadcrumbsSubject.next([]);
  }

  add(breadcrumb: Breadcrumb): void {
    const breadcrumbs = this.breadcrumbsSubject.getValue();
    breadcrumbs.push(breadcrumb);

    this.breadcrumbsSubject.next(breadcrumbs);
  }

  pop(): void {
    this.breadcrumbsSubject.next(this.breadcrumbsSubject.getValue().slice(0, -1));
  }

  to(breadcrumbKey: string) {
    const index = this.breadcrumbsSubject
      .getValue()
      .findIndex((breadcrumb) => breadcrumb.key === breadcrumbKey);

    const breadcrumbs = this.breadcrumbsSubject.getValue();
    this.breadcrumbsSubject.next(breadcrumbs.slice(0, index));
  }
}
