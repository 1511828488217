import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { BridgeMapsDetailsComponent } from './bridge-maps-details/bridge-maps-details.component';

import { BridgeMapsListComponent } from './bridge-maps-list/bridge-maps-list.component';

import { BridgeMapsRoutingModule } from './bridge-maps-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, BridgeMapsRoutingModule],
  declarations: [BridgeMapsListComponent, BridgeMapsDetailsComponent],
})
export class BridgeMapsModule {}
