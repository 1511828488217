<nav class="container container-wide flex justify-start">
  <div class="flex flex-1 justify-start items-center gap-x-12 container">
    <ul class="flex">
      @for (navItem of navItems; track navItem) {
      <li
        class="nav-item"
        routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: navItem?.isLinkActiveExact === true }"
      >
        @if (navItem !== undefined) {
        <a class="nav-link" routerLink="{{ navItem.route }}"> {{ navItem.label }} </a>
        }

        <div class="nav-bar"></div>
      </li>
      }
    </ul>
  </div>
</nav>
