import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { SessionRequestsControlComponent } from './session-requests-control/session-requests-control.component';
import { SessionRequestsControlsListComponent } from './session-requests-controls-list/session-requests-controls-list.component';
import { SessionRequestsDetailsComponent } from './session-requests-details/session-requests-details.component';

import { SessionRequestsListComponent } from './session-requests-list/session-requests-list.component';

import { SessionRequestsRoutingModule } from './session-requests-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, SessionRequestsRoutingModule],
  declarations: [
    SessionRequestsListComponent,
    SessionRequestsDetailsComponent,
    SessionRequestsControlsListComponent,
    SessionRequestsControlComponent,
  ],
})
export class SessionRequestsModule {}
