import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DateTime } from 'luxon';

import { DateRange, TimeframeOption } from './date-range-picker.model';

@UntilDestroy()
@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss'],
})
export class DateRangePickerComponent implements OnInit, AfterViewInit {
  @Output() dateRangeChanged: EventEmitter<DateRange> = new EventEmitter();

  public timeframeOptions: TimeframeOption[];
  public form: UntypedFormGroup;
  public dateRange: DateRange;

  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.timeframeOptions = [
      { value: '30', label: 'Since 30 Days Ago', numDays: 30 },
      { value: '90', label: 'Since 90 Days Ago', numDays: 90 },
      { value: '365', label: 'Since 1 Year Ago', numDays: 365 },
      { value: 'custom', label: 'Custom Range', numDays: 0 },
    ];

    this.form = this.formBuilder.group({
      timeframe: [this.timeframeOptions[2]],
      startsOn: [],
      endsOn: [],
    });

    this.updateTimeframe();
  }

  ngAfterViewInit() {
    this.form
      .get('timeframe')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe((timeFrame) => {
        this.updateTimeframe();
      });
  }

  updateTimeframe() {
    if (this.isCustomTimeframe()) {
      return;
    }

    this.startsOn = DateTime.local().minus({ days: this.timeframe.numDays }).toJSDate();
    this.endsOn = DateTime.local().toJSDate();

    this.emitDateRange();
  }

  emitDateRange() {
    this.dateRangeChanged.emit({
      startsOn: this.startsOn,
      endsOn: this.endsOn,
    });
  }

  isCustomTimeframe() {
    return this.timeframe.value === 'custom';
  }

  get timeframe() {
    return this.form.get('timeframe')?.value;
  }

  get startsOn(): Date {
    return this.form.get('startsOn')?.value;
  }

  set startsOn(date: Date) {
    this.form.get('startsOn')?.setValue(date);
  }

  get endsOn(): Date {
    return this.form.get('endsOn')?.value;
  }

  set endsOn(date: Date) {
    this.form.get('endsOn')?.setValue(date);
  }
}
