import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { Observable } from 'rxjs';

import { SegmentRequest, SegmentRequestOptions } from './segment-requests.model';

@Injectable({
  providedIn: 'root',
})
export class SegmentRequestsService extends ApiEntity<any> {
  protected override endpoint = 'segment-requests';

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(
    params: SegmentRequestOptions
  ): Observable<PaginatedList<SegmentRequest>> {
    return super.getList(params);
  }
}
