<section class="app mat-typography flex flex-col">
  @if (userLoaded) {
  <app-header> </app-header>
  }

  <main role="main">
    <router-outlet></router-outlet>
  </main>

  @if (userLoaded) {
  <app-footer> </app-footer>
  }
</section>
