import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PingCallbackComponent } from '@comsig/core';

export const routes: Routes = [
  {
    path: 'implicit/callback',
    component: PingCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
