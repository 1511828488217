import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-button',
  templateUrl: './add-button.component.html',
  styleUrls: ['./add-button.component.scss'],
})
export class AddButtonComponent {
  @Input() label: string;
  @Input() linkToRoute?: string | string[];

  @Output() addClicked: EventEmitter<null> = new EventEmitter();

  constructor(private router: Router) {}

  buttonClicked(): void {
    if (this.linkToRoute !== undefined) {
      if (typeof this.linkToRoute === 'string') {
        this.linkToRoute = [this.linkToRoute];
      }

      this.router.navigate(this.linkToRoute);
    } else if (this.addClicked.observed) {
      this.addClicked.emit();
    }
  }
}
