import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import { SessionRequest, SessionRequestOptions, SessionRequestsService } from '../';
import { SchedulesService } from '../../schedules';
import { BreadcrumbStateData } from '../../shared/breadcrumbs/breadcrumbs.service';

import { ListDataSource } from '../../shared/cdk';
import { ChildStatusCounts } from '../../shared/child-status-counts/child-status-counts.model';
import { SessionRequestsControlComponent } from '../session-requests-control/session-requests-control.component';
import { SessionRequestsControlsListComponent } from '../session-requests-controls-list/session-requests-controls-list.component';
import { SessionRequestsDetailsComponent } from '../session-requests-details/session-requests-details.component';

@UntilDestroy()
@Component({
  selector: 'app-session-requests-list',
  templateUrl: './session-requests-list.component.html',
  styleUrls: ['./session-requests-list.component.scss'],
})
export class SessionRequestsListComponent implements OnInit, AfterViewInit {
  public pageTitle = 'Session Requests';
  public pageSubtitle: string;

  public dataSource: ListDataSource<SessionRequest, SessionRequestsService>;
  public pageSizeOptions: number[] = [10, 25, 100];
  public initialPageSize = this.pageSizeOptions[0];
  public displayedColumns = [
    'googleSessionId',
    'googleMessageId',
    'status',
    'updatedAt',
    'merchants',
    'distinctTransactions',
    'actions',
  ];

  public statuses = [
    'ACCEPTED',
    'PROCESSING',
    'COUNTING',
    'COUNTED',
    'UNLOADING',
    'UNLOADED',
    'ENCRYPTING',
    'ENCRYPTED',
    'PROCESSED',
    'WAITING_SEGMENT_REQUEST',
    'PROCESSING_SEGMENT_REQUEST',
    'PROCESSED_SEGMENT_REQUEST',
    'WAITING_CONCLUDE_SESSION',
    'SESSION_DONE',
    'SESSION_FAILED',
    'SESSION_FAILED_INTERNAL',
    'SESSION_CANCELLED',
    'PAUSED',
    'RESUMED',
  ];

  public statusFilter = '';
  public searchFilter = '';
  public scheduleId: string;
  public childStatusCounts: ChildStatusCounts;

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild(SessionRequestsControlsListComponent)
  public sessionRequestsControlsListComponent: SessionRequestsControlsListComponent;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private schedulesService: SchedulesService,
    private sessionRequestsService: SessionRequestsService
  ) {
    if (this.route.snapshot.queryParams['scheduleId']) {
      this.scheduleId = this.route.snapshot.queryParams['scheduleId'];
      this.loadSchedule();
    }
  }

  ngOnInit() {
    this.dataSource = new ListDataSource(this.sessionRequestsService);
    this.paginator.pageSize = this.initialPageSize;
    this.sort.active = 'updatedAt';
    this.sort.direction = 'desc';

    this.loadPage();
  }

  ngAfterViewInit() {
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.loadPage(true));
    this.paginator.page.pipe(untilDestroyed(this)).subscribe(() => this.loadPage());
  }

  loadSchedule() {
    this.schedulesService
      .get(this.scheduleId)
      .pipe(first())
      .subscribe((schedule) => {
        this.pageSubtitle = `For Schedule ${schedule.googleMessageId}`;
        this.childStatusCounts = schedule.childStatusCounts;
      });
  }

  statusFilterChanged(): void {
    this.loadPage(true);
  }

  applySearchFilter(searchFilter: string): void {
    this.searchFilter = searchFilter;
    this.loadPage(true);
  }

  loadPage(resetPaginator = false): void {
    if (resetPaginator) {
      this.paginator.pageIndex = 0;
    }

    const params: SessionRequestOptions = {};

    if (this.searchFilter) {
      params.searchFilter = this.searchFilter;
    }

    if (this.statusFilter) {
      params.status = this.statusFilter;
    }

    if (this.scheduleId) {
      params.scheduleId = this.scheduleId;
    }

    this.dataSource.query(
      `${this.sort.active},${this.sort.direction}`,
      this.paginator.pageSize,
      this.paginator.pageIndex,
      params
    );
  }

  openDetailsModal(sessionRequest: SessionRequest): void {
    this.dialog.open(SessionRequestsDetailsComponent, {
      panelClass: ['comsig-dialog', 'details-dialog'],
      width: '700px',
      data: sessionRequest,
    });
  }

  openAddPauseModal(): void {
    const dialogRef = this.dialog.open(SessionRequestsControlComponent, {
      width: '600px',
      panelClass: 'comsig-dialog',
    });

    dialogRef.afterClosed().subscribe((success: boolean) => {
      if (success) {
        this.sessionRequestsControlsListComponent.retrieveList();
      }
    });
  }

  get breadcrumbLabel(): string {
    return this.pageSubtitle ? `${this.pageTitle} ${this.pageSubtitle}` : this.pageTitle;
  }

  get breadcrumbStateData(): BreadcrumbStateData {
    return {
      breadcrumb: {
        key: 'sessionRequests',
        label: this.breadcrumbLabel,
        route: this.route.routeConfig?.path,
        queryParams: this.route.snapshot.queryParams,
      },
    };
  }
}
