<ul>
  @for (status of statuses; track status) {
  <li>
    {{ status.status }} &#64; {{ status.createdAt | date: 'MMM d, y h:mm a' }}
    @if (status.comments?.errorMessage) {
    <div>({{ status.comments?.errorMessage }})</div>
    } @if (status.comments?.googleMessageId) {
    <div>(Google Message ID: {{ status.comments?.googleMessageId }})</div>
    }
  </li>
  }
</ul>
