import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pingAuthGuard } from '@comsig/core';

import { SessionRequestsListComponent } from './session-requests-list/session-requests-list.component';

const routes: Routes = [
  {
    path: 'session-requests',
    component: SessionRequestsListComponent,
    canActivate: [pingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SessionRequestsRoutingModule {}
