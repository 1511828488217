<app-dialog-header (closeDialog)="close()"> Data Verification </app-dialog-header>

<mat-dialog-content>
  <h2 class="font-bold">Successful Studies:</h2>
  @if (!isLoading) {
  <ul class="pt-4 pb-12">
    @for (study of successfulStudies; track study) {
    <li>{{ study }}</li>
    }
  </ul>
  } @else { @if (isLoading) {
  <mat-spinner class="spinner"> </mat-spinner>
  } }
</mat-dialog-content>
