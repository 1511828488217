<div class="container flex">
  <div class="flex items-center gap-x-4">
    @if (titlePrefix || pageTitle) {
    <h1>
      @if (titlePrefix) {
      <span class="title-prefix">{{ titlePrefix }}&nbsp;|&nbsp;</span>
      }{{ pageTitle }}
    </h1>
    } @if (pageSubTitle) {
    <h2 class="subtitle">{{ pageSubTitle }}</h2>
    }
    <ng-content select="[header-data]"></ng-content>
  </div>
  <div class="flex ml-auto"><ng-content></ng-content></div>
</div>
