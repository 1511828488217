<app-dialog-header (closeDialog)="close()"> Segment Request Details </app-dialog-header>

<mat-dialog-content>
  <div class="flex flex-col">
    <div class="row">
      <div>Segments Successful:</div>
      <div>
        {{ segmentRequest.segmentsOk | number }}
      </div>
    </div>
    <div class="row">
      <div>Segments Failing Bank Validation:</div>
      <div>
        {{ segmentRequest.segmentsFailNeb | number }}
      </div>
    </div>
    <div class="row">
      <div>Segments Failing 25 Check:</div>
      <div>
        {{ segmentRequest.segmentsFail25Check | number }}
      </div>
    </div>
    <div class="row">
      <div>Statuses:</div>
      <div>
        <app-status-list [statuses]="segmentRequest.statuses"></app-status-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
