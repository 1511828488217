import { Injectable } from '@angular/core';
import { ApiService } from '@comsig/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TransactionVerificationsService {
  protected endpoint = 'transaction-verifications';

  constructor(protected api: ApiService) {}

  succesfulStudies(): Observable<string[]> {
    return this.api.get(`${this.endpoint}/successful-studies`);
  }
}
