export interface NavItem {
  label: string;
  route: string;
  isLinkActiveExact?: boolean;
}

const navItems = new Map<string, NavItem>();

navItems.set('schedules', {
  label: 'Schedules',
  route: '/',
  isLinkActiveExact: true,
});

navItems.set('sessionRequests', {
  label: 'Session Requests',
  route: '/session-requests',
});

navItems.set('segmentRequests', {
  label: 'Segment Requests',
  route: '/segment-requests',
});

navItems.set('bridgeMaps', {
  label: 'Bridge Maps',
  route: '/bridge-maps',
});

navItems.set('merchants', {
  label: 'Merchants',
  route: '/merchants',
});

navItems.set('workQueues', {
  label: 'Work Queues',
  route: '/work-queues',
});

export { navItems };
