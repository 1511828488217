import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';
import { SchedulesDetailsComponent } from './schedules-details/schedules-details.component';

import { SchedulesListComponent } from './schedules-list/schedules-list.component';

import { SchedulesRoutingModule } from './schedules-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, SchedulesRoutingModule],
  declarations: [SchedulesListComponent, SchedulesDetailsComponent],
})
export class SchedulesModule {}
