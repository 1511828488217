import { NgModule } from '@angular/core';

import { LayoutModule } from '../layout/layout.module';
import { SharedModule } from '../shared/shared.module';

import { MerchantsListComponent } from './merchants-list/merchants-list.component';

import { MerchantsRoutingModule } from './merchants-routing.module';

@NgModule({
  imports: [LayoutModule, SharedModule, MerchantsRoutingModule],
  declarations: [MerchantsListComponent],
})
export class MerchantsModule {}
