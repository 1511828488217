import { Injectable } from '@angular/core';
import { ApiEntity, ApiService, PaginatedList } from '@comsig/core';
import { Observable } from 'rxjs';

import { BridgeMap, BridgeMapOptions } from './bridge-maps.model';

@Injectable({
  providedIn: 'root',
})
export class BridgeMapsService extends ApiEntity<BridgeMap> {
  protected override endpoint = 'bridge-maps';

  constructor(protected override api: ApiService) {
    super(api);
  }

  public override getList(params: BridgeMapOptions): Observable<PaginatedList<BridgeMap>> {
    return super.getList(params);
  }
}
