import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private ngxPermissionsService: NgxPermissionsService) {}

  setupPermissions(): void {
    this.ngxPermissionsService.flushPermissions();

    this.ngxPermissionsService.addPermission('pauseSessionRequests');
  }

  hasPermission(name: string): boolean {
    return this.ngxPermissionsService.getPermission(name) !== undefined;
  }
}
