import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, first } from 'rxjs/operators';

import { SessionRequestControlsService } from '../';
import { Merchant, MerchantsService } from '../../merchants';

@Component({
  selector: 'app-session-requests-control',
  templateUrl: './session-requests-control.component.html',
  styleUrls: ['./session-requests-control.component.scss'],
})
export class SessionRequestsControlComponent implements OnInit {
  public form: UntypedFormGroup;
  public isPending = false;

  public merchantOptions: Merchant[] = [];

  constructor(
    private dialogRef: MatDialogRef<SessionRequestsControlComponent>,
    private formBuilder: UntypedFormBuilder,
    private sessionRequestControlsService: SessionRequestControlsService,
    private merchantsService: MerchantsService
  ) {
    this.form = this.formBuilder.group({
      badDates: [[]],
      pauseDates: [[]],
      badMerchants: [[]],
      comments: [''],
    });
  }

  ngOnInit() {
    this.retrieveMerchants();
  }

  retrieveMerchants(): void {
    this.merchantsService
      .getList({ size: 2000 })
      .pipe(first())
      .subscribe((merchants) => {
        this.merchantOptions = merchants.content;
      });
  }

  close(): void {
    this.dialogRef.close(false);
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.isPending = true;

    const payload = {
      ...this.form.value,
      isActive: true,
    };

    this.sessionRequestControlsService
      .save(payload)
      .pipe(
        first(),
        finalize(() => (this.isPending = false))
      )
      .subscribe(() => {
        this.dialogRef.close(true);
      });
  }

  get badDatesFormControl(): UntypedFormControl {
    return this.form.get('badDates') as UntypedFormControl;
  }

  get pauseDatesFormControl(): UntypedFormControl {
    return this.form.get('pauseDates') as UntypedFormControl;
  }
}
