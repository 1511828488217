@if (hasDatasource) {
<div>
  <h2>Active Controls</h2>

  @if (isLoading) {
  <div class="mat-table-spinner-container">
    <mat-spinner> </mat-spinner>
  </div>
  }

  <table class="table-striped" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="isPauseAll">
      <th mat-header-cell *matHeaderCellDef>Scope</th>
      <td mat-cell *matCellDef="let row">
        <div>{{ row.isPauseAll ? 'Full Pause' : 'Partial Pause' }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="badMerchants">
      <th mat-header-cell *matHeaderCellDef>Bad Merchants</th>
      <td mat-cell *matCellDef="let row">
        @for (merchant of row.badMerchants; track merchant) {
        <div>
          {{ merchant.merchantName }}
        </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="badDates">
      <th mat-header-cell *matHeaderCellDef>Bad Dates</th>
      <td mat-cell *matCellDef="let row">
        @for (badDate of row.badDates; track badDate) {
        <div>
          {{ badDate | date: 'MMM d, y' }}
        </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="pauseDates">
      <th mat-header-cell *matHeaderCellDef>Pause Dates</th>
      <td mat-cell *matCellDef="let row">
        @for (pauseDate of row.pauseDates; track pauseDate) {
        <div>
          {{ pauseDate | date: 'MMM d, y' }}
        </div>
        }
      </td>
    </ng-container>

    <ng-container matColumnDef="comments">
      <th mat-header-cell *matHeaderCellDef>Comments</th>
      <td mat-cell *matCellDef="let row">
        {{ row.comments }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef>Created At</th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdAt | date: 'MMM d, y h:mm a' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="createdBy">
      <th mat-header-cell *matHeaderCellDef>Created By</th>
      <td mat-cell *matCellDef="let row">
        {{ row.createdBy }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef class="right-align">Actions</th>
      <td mat-cell *matCellDef="let row" class="right-align">
        <button
          mat-icon-button
          [matMenuTriggerFor]="actionMenu"
          *ngxPermissionsOnly="'pauseSessionRequests'"
        >
          <mat-icon class="cs cs-more-vert" aria-label="Actions"></mat-icon>
        </button>
        <mat-menu #actionMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="deactivate(row)">Deactivate</button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
}
