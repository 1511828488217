import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { FooterComponent } from './footer/footer.component';
import { HeaderMenuComponent } from './header/header-menu/header-menu.component';
import { HeaderNavComponent } from './header/header-nav/header-nav.component';
import { HeaderComponent } from './header/header.component';
import { PageHeaderComponent } from './page-header/page-header.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    HeaderMenuComponent,
    PageHeaderComponent,
    HeaderNavComponent,
  ],
  imports: [SharedModule],
  exports: [FooterComponent, HeaderComponent, PageHeaderComponent],
})
export class LayoutModule {}
