import { DataSource } from '@angular/cdk/collections';
import { ApiEntity, PaginatedList } from '@comsig/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { finalize, first } from 'rxjs/operators';

export class ListDataSource<T extends {}, S extends ApiEntity<T>> implements DataSource<T> {
  private listSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private subscription = new Subscription();

  public loading$ = this.loadingSubject.asObservable();
  public totalNumRows: number;

  constructor(private service: S) {}

  query(sort: string | string[], size: number, page: number = 0, filters: {} = {}) {
    this.loadingSubject.next(true);

    if (!this.subscription.closed) {
      this.subscription.unsubscribe();
    }

    const params = {
      sort,
      size,
      page,
      ...filters,
    };

    this.subscription = this.service
      .getList(params)
      .pipe(
        first(),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((response: PaginatedList<T>) => {
        this.totalNumRows = response.page.totalElements;
        this.listSubject.next(this.transformResponse(response.content));
      });
  }

  // this method is available to be overridden
  // if the response needs to be manipulated before
  // passed to the paginator
  transformResponse(response: T[]) {
    return response;
  }

  connect(): Observable<any[]> {
    return this.listSubject.asObservable();
  }

  disconnect(): void {
    this.listSubject.complete();
    this.loadingSubject.complete();
  }
}
