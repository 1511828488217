<app-dialog-header (closeDialog)="close()"> Bridge Map Details </app-dialog-header>

<mat-dialog-content>
  <div class="flex flex-col">
    <div class="row">
      <div>Bankbits File:</div>
      <div>{{ bridgeMap.bridgeIdBankbitsFile?.uri }}</div>
    </div>
    <div class="row">
      <div>Validation Request File:</div>
      <div>{{ bridgeMap.validationRequestFile?.uri }}</div>
    </div>
    <div class="row">
      <div>Public Key File:</div>
      <div>{{ bridgeMap.publicKeyFile?.uri }}</div>
    </div>
    <div class="row">
      <div>Proofs File:</div>
      <div>{{ bridgeMap.proofsFile?.uri }}</div>
    </div>
    <div class="row">
      <div>Encrypted Bankbits Files:</div>
      <div>
        @for (file of bridgeMap.bridgeIdEncryptedBankbitsFiles; track file) {
        <div>
          {{ file.uri }}
        </div>
        }
      </div>
    </div>
    <div class="row">
      <div>Statuses:</div>
      <div>
        <app-status-list [statuses]="bridgeMap.statuses"></app-status-list>
      </div>
    </div>
  </div>
</mat-dialog-content>
