import { Component, Input } from '@angular/core';

import { ChildStatusCounts } from './child-status-counts.model';

@Component({
  selector: 'app-child-status-counts',
  templateUrl: './child-status-counts.component.html',
  styleUrls: ['./child-status-counts.component.scss'],
})
export class ChildStatusCountsComponent {
  @Input() childStatusCounts: ChildStatusCounts;

  constructor() {}

  get statuses(): string[] {
    return Object.keys(this.childStatusCounts);
  }
}
