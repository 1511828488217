import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pingAuthGuard } from '@comsig/core';

import { SegmentRequestsListComponent } from './segment-requests-list/segment-requests-list.component';

const routes: Routes = [
  {
    path: 'segment-requests',
    component: SegmentRequestsListComponent,
    canActivate: [pingAuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SegmentRequestsRoutingModule {}
